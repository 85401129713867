<template>
  <step-lesson-tabs :tabs="tabs"></step-lesson-tabs>
</template>

<script>
import StepLessonTabs from '@/components/training/StepLessonTabs'
export default {
  name: 'Module2Part3Lesson',
  components: { StepLessonTabs },
  data () {
    return {
      tabs: [
        {
          name: 'tab3',
          label: this.$t('module2.part3.lesson3.tab'),
          component: () => import('@/components/training/module2/part3/Module2Part3Lesson3')
        },
        {
          name: 'tab2',
          label: this.$t('module2.part3.lesson2.tab'),
          component: () => import('@/components/training/module2/part3/Module2Part3Lesson2')
        },
        {
          name: 'tab1',
          label: this.$t('module2.part3.lesson1.tab'),
          component: () => import('@/components/training/module2/part3/Module2Part3Lesson1')
        }
      ]
    }
  }
}
</script>
